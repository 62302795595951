import React from "react";

import Home from "./Home";
import Skills from "./Skills";
import Aboutme from "./Aboutme";
import Projects from "./Projects";
import ContactMe from "./ContactMe";

import AnchorLink from "react-anchor-link-smooth-scroll";

const Layout = () => {
  // State to track loading status
  // const [isLoading, setIsLoading] = useState(true);

  // useEffect(() => {
  //   // Simulate a loading delay (e.g., fetching data or waiting for AOS initialization)
  //   setTimeout(() => {
  //     setIsLoading(false); // Stop loading after 3 seconds
  //   }, 1000);
  // }, []);

  // if (isLoading) {
  //   // If still loading, show the loader
  //   return (
  //     <div className="flex justify-center items-center min-h-screen">
  //       <div className="loader"> </div> {/* The CSS loader */}
  //     </div>
  //   );
  // }

  return (
    <div className="overflow-hidden">
      {/* Fixed Up Arrow */}
      <AnchorLink
        href="#home"
        className="animate-bounce fixed bottom-10 right-14 z-50 text-3xl text-white hover:text-green-300 cursor-pointer font-extrabold"
      >
        &#8593;
      </AnchorLink>

      {/* Home Section */}
      <section
        id="home"
        data-aos="zoom-in-up"
        data-aos-delay="300"
        className="min-h-screen p-10"
      >
        <Home />
      </section>

      {/* About Me Section */}
      <section
        id="aboutme"
        data-aos="fade-up-right"
        data-aos-delay="200"
        className="min-h-screen p-10"
      >
        <Aboutme />
      </section>

      {/* Skills Section */}
      <section
        id="skills"
        data-aos="fade-up-left"
        data-aos-delay="200"
        className="min-h-screen p-10"
      >
        <Skills />
      </section>

      {/* Projects Section */}
      <section
        id="projects"
        data-aos="fade-up-right"
        data-aos-delay="200"
        className="min-h-screen p-10"
      >
        <Projects />
      </section>

      {/* Contact Me Section */}
      <section
        id="contactme"
        data-aos="fade-up-left"
        data-aos-delay="200"
        className="min-h-screen p-10"
      >
        <ContactMe />
      </section>
    </div>
  );
};

export default Layout;
